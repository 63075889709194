.order-name {
  font-weight: bold;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
}

.creater-name {
  text-align: center;
}

.fit-picture {
  width: 250px;
  height: 335px;
}

.display-order-card {
  outline: 1px solid black;
  width: 250px;
  padding-bottom: 1px;
  margin-bottom: 10px;
  display: inline-block;
  margin-right: 20px;
}

.order-picture {
  display: block;
  margin-top: 10px;
  width: 200px;
}

.display-order {
  width: 100%;
}
