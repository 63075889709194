.bg-dark {
  background-color: rgba(22, 3, 75, 0.98) !important;
}

.navbar-dark .navbar-brand {
  color: #f03737;
  font-size: 32px;
  font-style: normal;
  font-weight: bold;
  padding-top: 0px;
  padding-bottom: 0px;
  width: 20%;
}

.navbar {
  padding-top: 4px;
  padding-bottom: 4px;
}

.navbar-middle {
  width: 60%;
}

.center-link {
  padding: 0 20px !important;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
}
