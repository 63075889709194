.homepage-explanations {
  color: rgba(22, 3, 75, 0.98);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  min-height: 700px;
  /* not sure this is correct - revisit and double check */
}

.homepage-checkbox-header {
  display: flex;
}

.homepage-header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: bold;
  font-size: 60px;
}

.homepage-checkbox {
  height: 100%;
  padding-top: 8px;
  padding-right: 8px;
}

.homepage-cassette-container {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.sign-in-sign-up-container {
  display: flex;
  margin-bottom: 10px;
}

.sign-in-sign-up {
  color: rgba(22, 3, 75, 0.42);
  font-size: 36px;
  background: rgba(254, 139, 139, 0.12);
  justify-content: space-around;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.selected {
  background: rgba(254, 139, 139, 0.35);
  color: rgba(22, 3, 75, 0.94);
}
